'use client';

import styled from 'styled-components';

const TitleStyle = styled.h1`
  margin-bottom: 2rem;
  font-size: 2rem;
  color: red;
`;

export function Title({ children }: { children: React.ReactNode }) {
  return <TitleStyle>{children}</TitleStyle>;
}
