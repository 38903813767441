'use client';

import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ThemeSwitcher } from '@/components/ThemeSwitcher/ThemeSwitcher';
import { Title } from '@/components/ui/Title/Title';
import { ROUTES } from '@/constants/common';

// TODO remove it later. This styles were created just for testing.
const FlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 30px;

  h1 {
    margin-bottom: 0;
  }

  a {
    display: block;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 7px;
  }
`;

export default function Home() {
  const { t } = useTranslation();
  return (
    <FlexGrid>
      <Title>{t('HomePage.title')}</Title>
      <ThemeSwitcher />
      <Link href={ROUTES.LOGIN}>{'Log In'}</Link>
    </FlexGrid>
  );
}
