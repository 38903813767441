/**
 * ThemeProvider Component
 *
 * This provider was created based on the `next-themes` library.
 * The core script and concepts were adapted with the following changes:
 *  - Replaced LocalStorage with cookies for theme persistence.
 *  - Simplified the provider's structure.
 *
 * This component provides theme management for the application, supporting:
 * - User-defined themes (e.g., 'light', 'dark').
 * - Automatic detection of system theme preferences (if `enableSystem` is true).
 * - Persistent theme storage using cookies, with support for custom cookie domains.
 * - Early theme application using a pre-hydration script to prevent theme flashes.
 *
 * Key Features:
 * - Initializes the theme based on a cookie value or a default theme.
 * - Updates the `data-theme` attribute on the HTML element for styling purposes.
 * - Allows users to manually switch themes with the `setTheme` function.
 * - Automatically adjusts the theme when the system theme changes (if `enableSystem` is enabled).
 *
 * Usage:
 * - Wrap your app with <ThemeProvider /> and provide optional configuration props.
 * - Use the `useTheme` hook to access or update the current theme in your components.
 *
 * Configuration:
 * - `themes`: Array of supported theme names. Defaults to ['light', 'dark'].
 * - `defaultTheme`: The fallback theme if no cookie is found. Defaults to 'system'.
 * - `enableSystem`: Whether to enable system theme detection. Defaults to true.
 * - `cookieKey`: The key used to store the theme in cookies. Defaults to 'theme'.
 * - `cookieDomain`: Optional custom domain for the theme cookie.
 *
 * Example:
 * ```tsx
 * <ThemeProvider defaultTheme="dark" enableSystem={true}>
 *   <App />
 * </ThemeProvider>
 * ```
 */

'use client';

import Cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import type { ThemeProviderProps, UseThemeProps } from './types';

const MEDIA = '(prefers-color-scheme: dark)';
const isServer = typeof window === 'undefined';

const ThemeContext = createContext<UseThemeProps | undefined>(undefined);
const defaultContext: UseThemeProps = { setTheme: () => {}, themes: [] };

export const useTheme = () => React.useContext(ThemeContext) ?? defaultContext;

// Helper to initialize the theme from cookies or fallback to default
const initializeTheme = (cookieKey: string, fallback: string): string => {
  if (isServer) return fallback;
  return Cookies.get(cookieKey) || fallback;
};

// Helper to determine the system theme
const getSystemTheme = (): string => {
  return window.matchMedia(MEDIA).matches ? 'dark' : 'light';
};

// Injects a script to set the `data-theme` attribute before React hydration
const ThemeScript = ({
  cookieKey,
  defaultTheme,
  enableSystem
}: {
  cookieKey: string;
  defaultTheme: string;
  enableSystem: boolean;
}) => {
  const scriptContent = `
    (function() {
      const MEDIA = '${MEDIA}';
      const el = document.documentElement;
      const getSystemTheme = () => window.matchMedia(MEDIA).matches ? 'dark' : 'light';
      const theme = document.cookie.split('; ').find(row => row.startsWith('${cookieKey}='))?.split('=')[1] || '${defaultTheme}';
      const resolvedTheme = theme === 'system' && ${enableSystem} ? getSystemTheme() : theme;
      el.setAttribute('data-theme', resolvedTheme);
      el.style.colorScheme = resolvedTheme === 'dark' ? 'dark' : 'light';
    })();
  `;

  return (
    <script
      dangerouslySetInnerHTML={{ __html: scriptContent }}
      suppressHydrationWarning
    />
  );
};

const Theme = ({
  cookieKey = 'theme',
  themes = ['light', 'dark'],
  defaultTheme = 'system',
  enableSystem = true,
  cookieDomain,
  children
}: ThemeProviderProps) => {
  const [theme, setThemeState] = useState(() =>
    initializeTheme(cookieKey, defaultTheme)
  );

  const setTheme = useCallback(
    (newTheme: string | ((currentTheme: string) => string)) => {
      const resolvedTheme =
        typeof newTheme === 'function' ? newTheme(theme) : newTheme;

      setThemeState(resolvedTheme);

      Cookies.set(cookieKey, resolvedTheme, {
        path: '/',
        domain: cookieDomain || undefined,
        expires: 30
      });

      document.documentElement.setAttribute('data-theme', resolvedTheme);
      document.documentElement.style.colorScheme =
        resolvedTheme === 'dark' ? 'dark' : 'light';
    },
    [theme, cookieKey, cookieDomain]
  );

  useEffect(() => {
    if (enableSystem && theme === 'system') {
      const mediaQuery = window.matchMedia(MEDIA);
      const handleSystemThemeChange = () => {
        const systemTheme = getSystemTheme();

        setThemeState(systemTheme);

        document.documentElement.setAttribute('data-theme', systemTheme);
        document.documentElement.style.colorScheme =
          systemTheme === 'dark' ? 'dark' : 'light';
      };

      handleSystemThemeChange();
      mediaQuery.addEventListener('change', handleSystemThemeChange);

      return () =>
        mediaQuery.removeEventListener('change', handleSystemThemeChange);
    }
  }, [theme, enableSystem]);

  const providerValue = useMemo(
    () => ({
      theme,
      setTheme,
      themes
    }),
    [theme, setTheme, themes]
  );

  return (
    <>
      <ThemeScript
        cookieKey={cookieKey}
        defaultTheme={defaultTheme}
        enableSystem={enableSystem}
      />
      <ThemeContext.Provider value={providerValue}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  const context = React.useContext(ThemeContext);

  if (context) return <>{props.children}</>;
  return <Theme {...props} />;
};
