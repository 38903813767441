'use client';

import { useEffect, useState } from 'react';

import { MoonIcon, SunIcon } from '@/components/ui/Icons/Icons';
import { useTheme } from '@/providers/ThemeProvider/ThemeProvider';

import * as S from './ThemeSwitcher.styles';

export const ThemeSwitcher = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <S.SwitcherWrapper onClick={toggleTheme} data-testid="theme-switcher">
      <S.IconsWrapper $isDarkMode={theme === 'dark'}>
        <SunIcon />
        <MoonIcon />
      </S.IconsWrapper>
      <S.SwitcherCircle $isDarkMode={theme === 'dark'} />
    </S.SwitcherWrapper>
  );
};
