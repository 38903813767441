import styled from 'styled-components';

import { flexBetween, flexCenterAligned } from '@/styles/common';

export const SwitcherWrapper = styled.div`
  position: relative;
  height: 44px;
  width: 110px;
  padding: 11px 19px;
  background-color: transparent;
  border-radius: 28px;
  border: 1px solid var(--border-main);
  ${flexBetween}
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const SwitcherCircle = styled.div<{ $isDarkMode: boolean }>`
  ${flexCenterAligned}
  position: absolute;
  top: 4px;
  left: ${({ $isDarkMode }) => ($isDarkMode ? 'calc(100% - 55px)' : '5px')};
  width: 50px;
  height: 34px;
  background-color: ${({ $isDarkMode }) =>
    $isDarkMode ? 'var(--textAndLabels-main)' : 'var(--bg-tertiary)'};
  border-radius: 17.5px;
  z-index: 1;
  transition:
    left 0.3s ease,
    background-color 0.3s ease;
`;

export const IconsWrapper = styled.div<{ $isDarkMode: boolean }>`
  width: 100%;
  ${flexBetween}
  z-index: 2;

  svg:nth-child(1) {
    color: var(--textAndLabels-main);
  }
  svg:nth-child(2) {
    color: ${({ $isDarkMode }) =>
      $isDarkMode ? 'var(--bg-main)' : 'var(--textAndLabels-main)'};
  }
`;
