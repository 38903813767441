export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  UPDATE_PASSWORD: '/update-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  VACANCIES: '/vacancies',
  RESUME: '/resume',
  FAVOURITES: '/favourites',
  ADVERTISEMENTS: '/advertisements'
};

export const AUTH_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.UPDATE_PASSWORD,
  ROUTES.RESET_PASSWORD
];

export const STATUS_CODES = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  LIMIT_EXCEEDED: 'LIMIT_EXCEEDED',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  SUCCESS: 'SUCCESS'
};

export const STORAGE_KEYS = {
  THEME: 'theme',
  LOCKOUT_END: 'lockout_end'
};

export const TIME_FORMAT = {
  FULL_DATE_TIME: 'D MMMM YYYY, HH:mm',
  TIME_ONLY: 'HH:mm'
};

export enum CardStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
  EXPIRED = 'expired'
}

export enum VacancyStateEnum {
  ALL = 'ALL',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED'
}
